import Vue from 'vue'
import moment from 'moment'

export default new Vue({
  methods: {
    convertForJSON (customFields = this.document.customFields) {
      const formatedCustomFields = {}
      customFields.forEach(cf => {
        formatedCustomFields[this.toCamelCase(cf.hostColumnName)] = cf.isDateType && cf.value != null ? moment(cf.value).format('YYYY-MM-DD') : cf.value
      })

      return formatedCustomFields
    }
  }
})
